import { Controller } from "stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    flatpickr($(this.element), {
      // enable time by adding the data attribute of flatpickr_calendar_time: true
      enableTime: this.data.has("time"),
      allowInput: true,
      // This sets the format to ISO8601 for time inputs but not for date inputs
      // @sergolius - https://github.com/flatpickr/flatpickr/issues/587
      dateFormat: this.data.has("time") ? "Z" : "Y-m-d",
      // This makes the field display in a more the default format while the value sent to the server 
      // will remain as ISO8601
      altInput: true,

      
      // For accessibility we are setting up some IDs onReady so the label points at the input that
      // flatpickr generates rather than the hidden input.

      onReady: (selectedDates, dateStr, instance) => {
        const id = instance.input.id;
        const input = instance.input.parentElement?.querySelector(".input");
        if (id && input) {
            instance.input.id = "flatpickr_hidden_" + id;
            input.id = id;
        }
      }
    })
  }

};