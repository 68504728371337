/* Modified version of */
/* https://codepen.io/maouida/pen/NPGaaN */
/* to not rely on the modals being on the page */
/* but rather can be created dynamically */
/* NCC & ANH - Fall 2018 */

var activeDialog = null;

window.addEventListener('DOMContentLoaded', (event) => {
  $( document ).on( "show.bs.modal", ".modal", function(e) {
    var newDialog = $(e.currentTarget).find('.modal-dialog');
    if(activeDialog){
      $(newDialog).data('previous-dialog', activeDialog);
      var stackedDialogCount = $('.modal .modal-dialog.aside').length;
      $(activeDialog).addClass('aside');
      $(activeDialog).addClass('aside-' + stackedDialogCount);
    }
    activeDialog = newDialog;
  })

  // when any modal is closing
  $( document ).on( "hide.bs.modal", ".modal", function(e) {
    var newDialog = $(activeDialog).data('previous-dialog');
    if (newDialog) {
      $(newDialog).removeClass('aside');
      $(activeDialog).data('previous-dialog', undefined);
    }
    activeDialog = newDialog;
  })
});
