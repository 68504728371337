import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import $ from "jquery"
$.ajaxSetup({
  beforeSend: (xhr, settings) => {
    // Because of ConstantContact we will fail CORs
    // if we send the csrf token in the header so we are
    // only sending the csrf token when sending data
    // to the local server.
    let a = document.createElement("a")
    a.href = settings.url
    if (a.hostname === window.location.hostname) {
      xhr.setRequestHeader("X-CSRF-Token", Rails.csrfToken())
    }
  },
});

// https://stackoverflow.com/questions/55895494/is-not-defined-when-installing-jquery-in-rails-via-webpack
window.jQuery = $;
window.$ = $;

require('@nathanvda/cocoon')
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import 'controllers'
import 'stylesheets/application'
import 'stacked_modals'
import 'stylesheets/stacked_modals'

import 'fullcalendar'
import 'fullcalendar/dist/fullcalendar.min.css'