import { FetchRequest } from '@rails/request.js'
import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  sortable = ""

  connect() {
    this.sortable = Sortable.create(document.getElementById("event_materials_list"), {
      onEnd: this.end.bind(this)
    })
  }

  async end(event) {
    let id = event.item.dataset.id;
    let formData = new FormData();
    formData.append("position", event.newIndex + 1);

    let url = this.element.dataset.url.replace(":id", id);

    const request = new FetchRequest("PATCH", url, { body: formData });

    const response = await request.perform();

    if (!response.ok) {
      console.error(`Error: ${response.statusText}`);
    }
  }
}

