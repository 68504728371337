import { Controller } from "stimulus"
import 'select2'
import 'select2/dist/css/select2.css'

export default class extends Controller {
  connect() {
    let ajaxParams = {}
    if (this.element.dataset.url !== undefined) {
      ajaxParams = {
        ajax: {
          url: this.element.dataset.url,
          dataType: "json",
          delay: 250,
          type: this.element.dataset.type || "POST",
          data: (params) => {
            return {
              term: params.term,
              ...JSON.parse(this.element.dataset.searchParams)
            }
          },
          processResults: function (data, params) {
            // parse the results into the format expected by Select2
            // since we are using custom formatting functions we do not need to
            // alter the remote JSON data, except to indicate that infinite
            // scrolling can be used
            params.page = params.page || 1;

            return {
              results: data,
              pagination: {
                more: (params.page * 30) < data.total_count
              }
            };
          },
          cache: true
        }
      }
    }
    this.select2 = $(this.element).select2({
      width: "100%",
      selectionCssClass: ":all:",
      ...ajaxParams
    })

    $(this.element).on("change", this.selectUpdated.bind(this))
    // Trigger a selectUpdated when the controller connects
    this.selectUpdated()

    // this removes the title HTML attribute which causes a redundant title error in the accessibility report
    $(".select2-selection__rendered").removeAttr('title')
  }

  disconnect() {
    this.select2.select2("destroy")
  }

  selectUpdated() {
    let selectedOption = this.element.querySelector("option:checked")
    // Here we will toggle a CSS class on the select whenever the selection of the select2 changes. This allows us to target styling based on if the select has a value or not.
    // Eventually this could go away with the use of a `:has` pseudo selector from CSS
    // Adding classes to the `select` and not the select2 because we own the select and we don't control the select2
    if (selectedOption.innerText || this.element.value) {
      this.element.classList.add("value-selected")
    } else {
      this.element.classList.remove("value-selected")
    }
  }
};