import { Controller } from "stimulus";
import $ from 'jquery';
import 'fullcalendar';

export default class extends Controller {
  static targets = ["calendar"];

  static values = {
    date: String,
    view: String
  }

  connect() {
    this.initializeCalendar();
    this.determineDefaultView();
    this.addLabelToButtons();
    this.hidePopovers();
  }
  
  disconnect() {
    if ($(this.element).data('fullCalendar')) {
      $(this.element).fullCalendar('destroy');
    }
  }

  hidePopovers() {
    $('.popover').each(function() {
      $(this).popover('hide');
      $(this).remove();
    });
  }

  initializeCalendar() {
    let calendarDiv = this.element.querySelector(".fc-toolbar");
    if (typeof(calendarDiv) == undefined || calendarDiv == null) {
      $(document).ready(() => {
        $(this.element).fullCalendar({
          defaultDate: this.dateValue || new Date(),
          defaultView: this.viewValue || 'month',
          height: 1000,
          events: function(start, end, timezone, callback) {
            $.ajax({
              url: '/events.json',
              dataType: 'json',
              data: {
                start: start.unix(),
                end: end.unix()
              },
              success: function(doc) {
                var events = eval(doc);
                callback(events);
              }
            });
          },
          eventRender: function(event, $el) {
            $el.find('span.fc-title').addClass('fc-event-text-break');
            $el.popover({
              title: event.title,
              content: event.description,
              trigger: 'hover',
              placement: 'top',
              container: 'body'
            });
          },
          header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,basicWeek,basicDay'
          },
          eventLimit: true // allow "more" link when too many events
        });
      });
    }
  }

  saveState() {
    this.element.dataset.eventCalendarDateValue = $(this.element).fullCalendar('getDate').toISOString()
    this.element.dataset.eventCalendarViewValue = $(this.element).fullCalendar('getView').name
  }

  determineDefaultView() {
    if (window.innerWidth < 650) {
      $(this.element.querySelector(".fc-basicDay-button")).click();
    } else if (window.innerWidth < 1000) {
      $(this.element.querySelector(".fc-basicWeek-button")).click();
    } else {
      $(this.element.querySelector(".fc-month-button")).click();
    }
  }

  addLabelToButtons() {
    var prevButton = this.element.querySelector(".fc-prev-button");
    var nextButton = this.element.querySelector(".fc-next-button");
    if (prevButton) {
      prevButton.setAttribute('aria-label', 'Previous Month');
    }
    if (nextButton) {
      nextButton.setAttribute('aria-label', 'Next Month');
    }
  }
}